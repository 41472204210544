.cardContainer {
  display: grid;
  grid-template-areas:
    "header"
    "content";
  grid-template-rows: auto 1fr;
  height: 100%;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: max-content;
  grid-area: header;
  margin-bottom: 10px;
}

.content {
  width: 100%;
  height: 100%;
  grid-area: content;
  overflow-y: auto;
}
