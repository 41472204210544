.imagePreviewPanel {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.imagePreviewPanel img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.imagePreviewPanelModal img {
  object-fit: contain;
}

.cardHeader {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.cardHeaderRight {
  display: flex;
  align-items: center;
  gap: 7px;
}

.navigationButtons {
  display: flex;
  gap: 7px;
}
