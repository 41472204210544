.loginContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.loginCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 35%;
  align-self: center;
  height: max-content;
}

.loginCard img {
  width: 25%;
  margin: 0 auto;
  margin-bottom: 12px;
}
