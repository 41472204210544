.navbarLeft {
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 18px;
  font-variant: small-caps;
  color: #8a9ba8;
}

.navbarRight {
  display: flex;
  gap: 12px;
  color: #8a9ba8;
}

.navbarRight span {
  cursor: pointer;
}
