.main {
  display: flex;
  flex-direction: column;
}

.panelContainer {
  --navbarHeight: 50px;
  display: flex;
  padding: 10px;
  gap: 10px;

  /* In order to force our browsers to respect 'height: 100%' in the children,
   * we need to give the parent height a hard px value which we can calculate
   * as long as the navbar height is fixed.
  */
  height: calc(100vh - var(--navbarHeight));
}

.panelLeft {
  display: flex;
  flex-direction: column;
  width: 35%;
  gap: 10px;
}

.panelMiddle {
  width: 40%;
}

.panelRight {
  width: 25%;
}
