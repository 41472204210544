.imageGalleryPanel {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 10px;
}

.imageGalleryPanel img {
  width: 100%;
  border-radius: 2px;
  cursor: pointer;
}
