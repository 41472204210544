.assessmentPanel {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.assessmentPanel textarea::placeholder {
  font-family: sans-serif;
  font-weight: 600;
  text-transform: uppercase;
}

.schematicGrid {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  flex: 1 0 auto;
  /* Hack to ensure flex expands to full height end ensures 
   * child svg doesn't use it's own height */
  height: 0;
}

.schematicGrid svg {
  height: 100%;
}

.schematicGridResetBtn {
  position: absolute;
  right: 6px;
}

.formGrid {
  display: grid;
  grid-template-areas: "left right";
  gap: 10px;
  padding-left: 4px;
  padding-right: 4px;
}

.controlsLeft {
  grid-area: left;
}

/* apply to all but the 1st div-child inside controlsLeft */
.controlsLeft > div:not(:first-child) {
  margin-top: 12px;
}

.controlsRight {
  grid-area: right;
}

.controlsRight > label:first-of-type {
  margin-top: 0;
}

.infoPanelGrid {
  display: grid;
  grid-template-areas: "leftTitle leftInfo rightTitle rightInfo";
  gap: 10px;
}

.infoPanelGrid p {
  font-variant: small-caps;
  font-size: 12px;
  font-weight: bold;
}

.titlesLeft {
  grid-area: leftTitle;
}

.infoLeft {
  grid-area: leftInfo;
  text-align: right;
}

.titlesRight {
  grid-area: rightTitle;
}

.infoRight {
  grid-area: rightInfo;
  text-align: right;
}

.titlesLeft {
  color: grey;
}

.titlesRight {
  color: grey;
}

.schematicMiddle {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  grid-area: middle;
}
